.roxy-advent-modal__plyr {
  width: 100%;
}

.roxy-advent-modal__body {
  padding: 0;
}

.modal-content {
  border: unset;
}

.modal-header {
  border: unset;
}

.roxy-advent-modal_body--content {
  padding: var(--roxy-advent-spacer-sm);
}
