.roxy-advent-toast {
  border: 0;

  filter: drop-shadow(0 0 2px #cecece);

  @media (max-width: map-get($roxy-advent-breakpoints , sm )) {
    width: 95%;
  }
}

.roxy-advent-header__collapse {
  padding: 10px;
}

.roxy-advent-header__collapse-button:focus {
  outline: none;
  box-shadow: none;
}
