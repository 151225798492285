.roxy-advent-login-page {
  text-align: center;
}

.roxy-advent-login-page__login-jumbo {
  margin: auto;
  max-width: 500px;
  filter: drop-shadow(0 0 5px grey);
  .card {
    border: unset;
    .card-body {
      padding: 0 var(--roxy-advent-spacer-sm) 0 var(--roxy-advent-spacer-sm);
    }
  }
}

.roxy-advent-login-page__login-wave {
  position: relative;
  height: 350px;
  border-radius: 3px 3px 0 0;
  min-width: 100%;
  background: var(--roxy-advent-color-primary);
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 1; // needed for safari
  img {
    padding-bottom: 50px;
    width: 250px;
  }
}

.roxy-advent-login-page__login-wave:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 120%;
  height: 150px;
  background-color: white;
  left: -30px;
  top: 280px;
}

.roxy-advent-login-page__form {
  margin: var(--roxy-advent-spacer-lg) 0 var(--roxy-advent-spacer-lg) 0;
  display: grid;
  row-gap: var(--roxy-advent-spacer-sm);
  grid-template-rows: auto;
}
