@import "../../../styles/variables";

.roxy-advent-background-switcher {
  border-radius: 10px;
  background-color: rgba(white, 0.5);
  border: 1px solid var(--roxy-advent-color-red);
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  width: 100px;
  grid-template-areas: "left right";
  z-index: 600;
}

.roxy-advent-background-switcher-left {
  grid-area: left;
  display: grid;
  margin-left: 5px;
  cursor: pointer;
}

.roxy-advent-background-switcher-right {
  grid-area: right;
  display: grid;
  justify-content: end;
  margin-right: 10px;
  cursor: pointer;
}
