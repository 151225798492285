@import "../../../styles/variables";

.roxy-advent-calendar {
  max-width: 1000px;
  margin: auto;
}

.roxy-advent-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.roxy-advent-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
