:root {
  //general
  --roxy-advent-spacer-sm: 1rem;
  --roxy-advent-spacer-md: 1.5rem;
  --roxy-advent-spacer-lg: 2rem;

  --roxy-advent-header-footer-padding: 0 var(--roxy-advent-spacer-sm) 0
    var(--roxy-advent-spacer-sm);

  --roxy-advent-container-padding: 0 var(--roxy-advent-spacer-lg) 0
    var(--roxy-advent-spacer-lg);

  // colors
  --roxy-advent-color-rose-water-weaker: #7bbcb523;
  --roxy-advent-color-primary: #7bbcb5;
  --roxy-advent-color-red: #ba3f5b;
  --roxy-advent-color-hot-pink: #47d7c6;
  --roxy-advent-color-rose-water: #f67280;
  --roxy-advent-color-rose-water-weak: #a2d5f2;
  --roxy-advent-color-spearmint-dark: #ff7e67;
  --roxy-advent-color-spearmint: #e0ebe9;
  --roxy-advent-color-dark: #4e4e4e;

  // app-shell
  --roxy-advent-app-shell-row-gap: 3rem;
  --roxy-advent-app-shell-padding: 0 1rem 0 1rem;
}

$roxy-advent-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

@import "~bootstrap/scss/functions";

/*! `Custom` Bootstrap 4 theme */
@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Quicksand:200,300,400,700);
$headings-font-family: Quicksand;

$enable-grid-classes: false;
$primary: #7bbcb5;
$secondary: #ba3f5b;
$success: #47d7c6;
$danger: #f67280;
$info: #a2d5f2;
$warning: #ff7e67;
$light: #e0ebe9;
$dark: #4e4e4e;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Add SASS theme customizations here..
