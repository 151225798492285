@import "../../../styles/variables";

:root {
  --roxy-advent-calendar-item-width: 100%;
  --roxy-advent-calendar-item-max-height: 800px;
}

.roxy-advent-calendar-item {
  transform: translate3d(0, 0, 0) translateZ(1000px);
}

.roxy-advent-calendar-item__door {
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
  width: (var(--roxy-advent-calendar-item-width));
  min-height: 100%;
  max-height: var(--roxy-advent-calendar-item-max-height);
  background: var(--roxy-advent-color-primary);
  border-radius: 6px;
  color: #fcfcfc;
  transform-origin: left;
  /*Speed of the Door animation*/
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0px;
  left: 0px;
}

.roxy-advent-calendar-item__door-open {
  /*prespectiv creates the door open effect*/
  transform: perspective(2000px) translateZ(0px) translateX(0px) translateY(0px)
    rotateY(-94deg);

  @media (max-width: map-get($roxy-advent-breakpoints , xl )) {
    transform: perspective(2000px) translateZ(0px) translateX(0px)
      translateY(0px) rotateY(-94deg);
  }
  @media (max-width: map-get($roxy-advent-breakpoints , lg )) {
    transform: perspective(1700px) translateZ(0px) translateX(0px)
      translateY(0px) rotateY(-93deg);
  }
  @media (max-width: map-get($roxy-advent-breakpoints , sm )) {
    transform: perspective(1700px) translateZ(0px) translateX(0px)
      translateY(0px) rotateY(-92deg);
  }
}

.roxy-advent-calendar-item__door-open-possible {
  /*prespectiv creates the door open effect*/
  transform: perspective(2200px) translateZ(0px) translateX(0px) translateY(0px)
    rotateY(-30deg);
}

.roxy-advent-calendar-item-surprise {
  display: grid;
  grid-template-areas: "title" "header" "text" "actions";
  row-gap: 5px;
}

.roxy-advent-calendar-item-surprise__title {
  display: grid;
  grid-area: title;
  margin: 10px 10px 0 10px;
  .h6 {
    margin-bottom: 4px;
  }
}

.roxy-advent-calendar-item-surprise__header {
  text-align: center;
  grid-area: header;
  border-radius: 6px 6px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  img {
    z-index: -1;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.roxy-advent-calendar-item-surprise__text {
  display: grid;
  grid-area: text;
  margin: 0 10px 0 10px;
  h1 {
    font-size: large;
  }
}

.roxy-advent-calendar-item-surprise__actions {
  display: grid;
  grid-area: actions;
  justify-content: center;
  margin: 10px 1.5rem 10px 1.5rem;
  grid-template-columns: 1fr;
}

.roxy-advent-calendar-item {
  margin-bottom: 30px;
  display: grid;
  background: #fcfcfc;
  position: relative;
  min-height: 200px;
  max-height: var(--roxy-advent-calendar-item-max-height);
  border-radius: 6px;
}

.roxy-advent-calendar-item.closed:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.roxy-advent-calendar-item-surprise__header__multi {
  position: absolute;
  display: grid;
  justify-content: right;
  padding: 1rem 1rem 0 0;
  width: 100%;
  right: 0;
  color: #fcfcfc;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
