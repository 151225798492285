@import "../../../styles/variables";

body {
  margin: 0;
  background-color: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .modal-header {
    .close {
      display: none;
    }
  }
  padding: 0 !important;
}

.modal-backdrop.fade.show {
  transform: translate3d(0, 0, 0) translateZ(1000px);
}

.modal.fade.show {
  transform: translate3d(0, 0, 0) translateZ(1000px);
}

.roxy-advent-app-shell {
  display: grid;
  row-gap: var(--roxy-advent-app-shell-row-gap);
  grid-template-columns: auto;
  grid-template-rows: min-content auto min-content;
  grid-template-areas: "header" "content" "footer";
}

.roxy-advent-app-shell__header {
  position: sticky;
  top: 0;
  z-index: 100;
  grid-area: header;
  filter: drop-shadow(0 0 5px gray);
  transform: translate3d(0, 0, 0) translateZ(1000px);
}

.roxy-advent-app-shell__toasts {
  position: fixed;
  display: grid;
  row-gap: 10px;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  transform: translate3d(0, 0, 0) translateZ(1000px);
  @media (max-width: map-get($roxy-advent-breakpoints , sm )) {
    right: unset;
    justify-items: center;
    width: 100%;
    top: 75px;
    bottom: unset;
  }
}

.roxy-advent-app-shell__content {
  grid-area: content;
}

.roxy-advent-app-shell-container {
  padding: var(--roxy-advent-container-padding);
}

.roxy-advent-app-shell__footer {
  grid-area: footer;
}

.roxy-advent-app-shell__footer--children {
  padding: var(--roxy-advent-header-footer-padding);
}

.roxy-advent-app-shell__toast-body {
  padding: 0;
}

.roxy-advent-app-shell__toast-content {
  display: grid;
  grid-template-areas: "notice action";
  grid-template-columns: auto 80px;
  min-height: 65px;
  background: rgba(123, 188, 181, 0.3);
}

.roxy-advent-app-shell__toast-content--notice {
  grid-area: notice;
  display: grid;
  align-items: center;
  padding: 15px;
}

.roxy-advent-app-shell__toast-content--action {
  grid-area: action;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 6px 0 0 6px;
  background: rgba(123, 188, 181, 0);
  border-left: 1px solid var(--roxy-advent-color-primary);
}

.roxy-advent-app-shell__toast-content--action:hover {
  grid-area: action;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 6px 6px 6px 6px;
  background-color: var(--roxy-advent-color-rose-water-weaker);
  border-left: 1px solid var(--roxy-advent-color-primary);
}
