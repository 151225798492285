@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap";
@import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader;

// Pages
@import "../app/pages/calendar-page";
@import "../app/pages/events-page";
@import "../app/pages/login-page";

// Components
@import "../app/components/app-shell/";
@import "../app/components/background-controller";
@import "../app/components/background-switcher";
@import "../app/components/calendar";
@import "../app/components/calendar-item";
@import "../app/components/header";
@import "../app/components/help-component";
@import "../app/components/install-pwa";
@import "../app/components/modal";
@import "../app/components/toast";
@import "../app/components/plyr-video-player";
@import "../app/components/spinner";
