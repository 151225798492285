@import "~plyr/src/sass/plyr";

.plyr--full-ui input[type="range"] {
  color: red;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: red;
}

.plyr__control--overlaid {
  background: rgba(235, 25, 25, 0.6);
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
}

.plyr__menu__container
  .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
  background: red;
}
