@import "../../../styles/variables";

.roxy-advent-header {
  margin: 0 var(--roxy-advent-spacer-sm) 0 var(--roxy-advent-spacer-sm);
  img {
    height: 40px;
  }
}

.roxy-advent-header__spacer {
  flex: auto;
}

.roxy-advent-header__navigation {
  justify-items: flex-end;
  display: grid;
}

.roxy-advent-navigation-entry {
  display: grid;
  grid-template-areas: "icon label";
  grid-template-columns: 1.5rem auto;
  padding: 5px 5px 5px 5px;
  color: var(--roxy-advent-color-primary);
  cursor: default;
  font-weight: bold;
}

.roxy-advent-navigation-entry.pointer:hover {
  background-color: var(--roxy-advent-color-rose-water-weaker);
}

.roxy-advent-navigation-entry.pointer {
  cursor: pointer;
  font-weight: normal;
}

.roxy-advent-navigation-entry__icon {
  grid-area: icon;
  display: grid;
  align-items: center;
}

.roxy-advent-navigation-entry__label {
  grid-area: label;
}
